import { graphql, useFragment } from "react-relay"
import { useVariant } from "@/hooks/useVariant"
import { useIsGaslessCancellationEnabled_order$key } from "@/lib/graphql/__generated__/useIsGaslessCancellationEnabled_order.graphql"

type UseIsGaslessCancellationEnabledProps = {
  order?: useIsGaslessCancellationEnabled_order$key
}

export const useIsGaslessCancellationEnabled = ({
  order: orderDataKey,
}: UseIsGaslessCancellationEnabledProps = {}): {
  isEnabled: boolean
  isEnabledForCollectionOffers: boolean
  isEnabledForOffers: boolean
  isEnabledForListings: boolean
} => {
  const { payload, enabled } = useVariant("is_gasless_cancellation_enabled")

  const orderData = useFragment(
    graphql`
      fragment useIsGaslessCancellationEnabled_order on OrderV2Type {
        orderType
        side
      }
    `,
    orderDataKey ?? null,
  )

  if (!enabled) {
    return {
      isEnabled: false,
      isEnabledForCollectionOffers: false,
      isEnabledForOffers: false,
      isEnabledForListings: false,
    }
  }

  if (payload.value === "collection-offers") {
    return {
      isEnabled: !!orderData && orderData.orderType === "CRITERIA",
      isEnabledForCollectionOffers: true,
      isEnabledForOffers: false,
      isEnabledForListings: false,
    }
  }

  if (payload.value === "offers") {
    return {
      isEnabled: !!orderData && orderData.side === "BID",
      isEnabledForCollectionOffers: true,
      isEnabledForOffers: true,
      isEnabledForListings: false,
    }
  }

  return {
    isEnabled: true,
    isEnabledForCollectionOffers: true,
    isEnabledForOffers: true,
    isEnabledForListings: true,
  }
}
