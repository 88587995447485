/**
 * @generated SignedSource<<e28ac81a82a996dd066799d70bee9592>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditListingItemDetails_order$data = {
  readonly item: {
    readonly __typename: "AssetType";
    readonly defaultRarityData: {
      readonly rank: number;
    } | null;
    readonly displayName: string;
    readonly isCurrentlyFungible: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExpirationDate_data" | "OrderPrice" | "OrderUsdPrice">;
  readonly " $fragmentType": "EditListingItemDetails_order";
};
export type EditListingItemDetails_order$key = {
  readonly " $data"?: EditListingItemDetails_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditListingItemDetails_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditListingItemDetails_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCurrentlyFungible",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RarityDataType",
              "kind": "LinkedField",
              "name": "defaultRarityData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rank",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AssetMedia_asset"
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderUsdPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpirationDate_data"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "e18bc6f30e265178e92ff5a9143385f5";

export default node;
