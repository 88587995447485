import React from "react"
import { Text } from "@opensea/ui-kit"
import { Modal } from "@/design-system/Modal"
import { MultiStepModalProps } from "@/design-system/Modal/MultiStepModal.react"
import { useTranslate } from "@/hooks/useTranslate"

type Props = Pick<MultiStepModalProps, "onPrevious" | "onClose">

export const QuickListingFlowModalErrorContent = ({
  onPrevious,
  onClose,
}: Props) => {
  const t = useTranslate("sell")
  return (
    <>
      <Modal.Header onPrevious={onPrevious} />
      <Modal.Body className="text-center">
        <Modal.Body.Title className="mb-6">
          {t("quickListingFlowModalErrorContent.title", "Listing failed")}
        </Modal.Body.Title>
        <Text>
          {t(
            "quickListingFlowModalErrorContent.description",
            "It looks like this item is no longer available to list. Please refresh the page and try again.",
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("quickListingFlowModalErrorContent.close", "Close")}
        </Modal.Footer.Button>
        <Modal.Footer.Button
          icon="refresh"
          onClick={() => window.location.reload()}
        >
          {t("quickListingFlowModalErrorContent.refresh", "Refresh")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
