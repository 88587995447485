import React, { ComponentProps, useCallback } from "react"
import { Icon, UnstyledButton } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Source } from "@/components/trade/analytics"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { CreateQuickSingleListingFlowModal } from "@/features/sell/components/CreateQuickSingleListingFlowModal.react"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { trackCreateListingButton } from "@/lib/analytics/events/itemEvents"
import { CreateListingButton_item$key } from "@/lib/graphql/__generated__/CreateListingButton_item.graphql"
import { getItemSellUrl } from "@/lib/helpers/item"

type Props = {
  isIcon?: boolean
  className?: string
  children?: React.ReactNode
  item: CreateListingButton_item$key
  source: Source
  openFullPage?: boolean
  onListingCreated?: (itemRelayId: string) => unknown
  trigger?: (onClick: () => unknown) => React.ReactNode
} & Omit<ButtonProps, "href" | "onClick" | "order"> &
  Pick<
    ComponentProps<typeof CreateQuickSingleListingFlowModal>,
    "initialQuantity"
  >

export const CreateListingButton = ({
  className,
  children,
  item: itemKey,
  initialQuantity,
  source,
  trigger,
  openFullPage = false,
  onListingCreated,
  isIcon = false,
  ...buttonProps
}: Props) => {
  const t = useTranslate("components")
  const { openModal } = useGlobalModal()
  const item = useFragment(
    graphql`
      fragment CreateListingButton_item on ItemType {
        __typename
        ... on AssetType {
          ...CreateQuickSingleListingFlowModal_asset
        }
        ...itemEvents_dataV2
        ...item_sellUrl
      }
    `,
    itemKey,
  )

  const router = useRouter()

  const handleOnClick = useCallback(() => {
    trackCreateListingButton(item, {
      source,
    })
    if (openFullPage) {
      router.push(getItemSellUrl(item))
      return
    }
    if (item.__typename === "AssetType") {
      openModal(
        close => (
          <CreateQuickSingleListingFlowModal
            asset={item}
            close={close}
            initialQuantity={initialQuantity}
            onSubmitted={orderItemRelayId => {
              if (orderItemRelayId && onListingCreated) {
                onListingCreated(orderItemRelayId)
              }
            }}
          />
        ),
        {
          position: "centered",
          size: "medium",
        },
      )
    }
  }, [
    item,
    source,
    openFullPage,
    router,
    openModal,
    initialQuantity,
    onListingCreated,
  ])

  const defaultLabel = t("createListingButton.listForSale.cta", "List for sale")

  return (
    <>
      {trigger ? (
        trigger(handleOnClick)
      ) : isIcon ? (
        <Tooltip content={defaultLabel}>
          <UnstyledButton
            className={className}
            style={{ padding: "2px" }}
            onClick={e => {
              e.stopPropagation()
              return handleOnClick()
            }}
          >
            <Icon aria-label={defaultLabel} value="sell" />
          </UnstyledButton>
        </Tooltip>
      ) : (
        <Button className={className} onClick={handleOnClick} {...buttonProps}>
          {children || defaultLabel}
        </Button>
      )}
    </>
  )
}
