/**
 * @generated SignedSource<<90a65e6ba3ed70f1995c4ec0c4e9ee83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCartAndQuickBuyButton_order$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemAddToCartButton_order" | "QuickBuyButton_order" | "useIsQuickBuyEnabled_order">;
  readonly " $fragmentType": "AddToCartAndQuickBuyButton_order";
};
export type AddToCartAndQuickBuyButton_order$key = {
  readonly " $data"?: AddToCartAndQuickBuyButton_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCartAndQuickBuyButton_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddToCartAndQuickBuyButton_order",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsQuickBuyEnabled_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemAddToCartButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickBuyButton_order"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "8e84728ffe28f86af8a9a17871661258";

export default node;
