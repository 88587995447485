import React from "react"
import { Text, VerticalAligned } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackEditListingWarningModal,
  trackDismissEditListingWarningModal,
  trackingReviewListings,
} from "@/lib/analytics/events/itemEvents"

type Props = {
  onClose: () => unknown
  onReviewListings?: () => unknown
}

export const LowerPriceListingWarningModal = ({
  onClose,
  onReviewListings,
}: Props) => {
  const t = useTranslate("sell")
  const handleReviewListings = () => {
    trackingReviewListings()
    onClose()
    onReviewListings?.()
  }
  const handleDismissModal = () => {
    trackDismissEditListingWarningModal()
    onClose()
  }

  useMountEffect(() => {
    trackEditListingWarningModal()
  })

  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {t("lowerListing.warning.header", "Cancel active listings")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <VerticalAligned className="items-center">
          <Image
            alt=""
            height={141}
            src="/static/images/lower-price-warning.png"
            width={200}
          />
          <Text asChild>
            <p>
              {t(
                "lowerListing.warning.body",
                `When you lower the price of your item, we don’t cancel the
              previous listing (to save gas). If you intend to remove the item
              from sale, review and cancel the listings.`,
              )}
            </p>
          </Text>
        </VerticalAligned>
      </Modal.Body>
      <Modal.Footer>
        <Button
          href={!onReviewListings ? "/account?tab=listings" : undefined}
          variant="secondary"
          onClick={handleReviewListings}
        >
          {t("lowerListing.warning.button.review.label", "Review listings")}
        </Button>
        <Block marginLeft="8px">
          <Button onClick={handleDismissModal}>
            {t("lowerListing.warning.button.done.label", "Done")}
          </Button>
        </Block>
      </Modal.Footer>
    </>
  )
}
