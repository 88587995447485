import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Overflow } from "@/components/common/Overflow"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { Item, ItemProps } from "@/design-system/Item"
import { getSelectedPaymentAsset } from "@/features/sell/utils/assets"
import { useTranslate } from "@/hooks/useTranslate"
import { QuickListingItemDetails_asset$key } from "@/lib/graphql/__generated__/QuickListingItemDetails_asset.graphql"
import { sellPageQueries_paymentAssets$key } from "@/lib/graphql/__generated__/sellPageQueries_paymentAssets.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BigNumber,
  display,
  displayUSD,
  NumberInput,
} from "@/lib/helpers/numberUtils"
import { SellPagePaymentAssetsFragment } from "../queries/sellPageQueries"

type QuickListingItemDetailsProps = {
  asset: QuickListingItemDetails_asset$key | null
  price: BigNumber
  selectedPaymentAssetRelayId: string
  paymentAssetsDataKey: sellPageQueries_paymentAssets$key | null
  quantity?: NumberInput
} & ItemProps

export const QuickListingItemDetails = ({
  asset: itemKey,
  price,
  selectedPaymentAssetRelayId,
  paymentAssetsDataKey,
  quantity = 1,
  ...itemProps
}: QuickListingItemDetailsProps) => {
  const t = useTranslate("sell")
  const paymentAssets = useFragment(
    SellPagePaymentAssetsFragment,
    paymentAssetsDataKey,
  )
  const asset = useFragment(
    graphql`
      fragment QuickListingItemDetails_asset on AssetType {
        displayName
        isCurrentlyFungible
        defaultRarityData {
          rank
        }
        collection {
          ...CollectionLink_collection
        }
        assetContract {
          ...CollectionLink_assetContract
        }
        ...StackedAssetMedia_assets
      }
    `,
    itemKey,
  )
  if (!asset || !paymentAssets) {
    return null
  }

  const { isCurrentlyFungible } = asset

  const selectedPaymentAsset =
    selectedPaymentAssetRelayId &&
    getSelectedPaymentAsset(paymentAssets, selectedPaymentAssetRelayId)

  return (
    <Item
      {...itemProps}
      className={classNames("border-0 py-2", itemProps.className)}
    >
      <ItemQuantityBadge
        overrides={{
          QuantityBadge: { props: { className: classNames("right-3") } },
        }}
        quantity={quantity}
      >
        <Item.Avatar size={84}>
          <StackedAssetMedia assets={[asset]} variant="small" />
        </Item.Avatar>
      </ItemQuantityBadge>
      <Item.Content>
        <Item.Title>
          <Text
            data-testid="QuickListingItemDetails--name"
            size="medium"
            weight="semibold"
          >
            {asset.displayName}
          </Text>
        </Item.Title>
        <Item.Description>
          <Overflow>
            <Text.Body size="small">
              <CollectionLink
                assetContract={asset.assetContract}
                collection={asset.collection}
                isSmall
                linkStyle={{
                  color: "inherit",
                }}
              />
            </Text.Body>
          </Overflow>
        </Item.Description>
        {asset.defaultRarityData && (
          <Item.Description>
            <Overflow>
              <Text.Body className="text-secondary" size="tiny">
                {t(
                  "quickListingItemDetails.rarityRank",
                  "Rarity #{{rarityRank}}",
                  { rarityRank: asset.defaultRarityData.rank },
                )}
              </Text.Body>
            </Overflow>
          </Item.Description>
        )}
      </Item.Content>
      <Item.Side className="max-w-full">
        <Item.Title>
          <Text.Body className="text-secondary" size="tiny">
            {isCurrentlyFungible
              ? t(
                  "quickListingItemDetails.listingPricePerItem",
                  "Listing price per item",
                )
              : t("quickListingItemDetails.listingPrice", "Listing price")}
          </Text.Body>
          {selectedPaymentAsset && (
            <>
              <Text
                className="-mb-0.5 flex justify-end"
                data-testid="QuickListingItemDetails--total-price"
                size="medium"
                weight="semibold"
              >
                {price.gt(0) && !price.isNaN()
                  ? display(price.toString(), selectedPaymentAsset.symbol)
                  : EMPTY_PRICE_DISPLAY}{" "}
                {selectedPaymentAsset.symbol}
              </Text>
              {selectedPaymentAsset.usdSpotPrice &&
                price.gt(0) &&
                !price.isNaN() && (
                  <Text.Body className="text-secondary" size="tiny">
                    {t(
                      "quickListingItemDetails.usdPrice",
                      "${{usdPrice}} USD",
                      {
                        usdPrice: displayUSD(
                          price.times(selectedPaymentAsset.usdSpotPrice),
                        ),
                      },
                    )}
                  </Text.Body>
                )}
            </>
          )}
        </Item.Title>
      </Item.Side>
    </Item>
  )
}
