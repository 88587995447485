import React, { useCallback, useEffect, useRef, useState } from "react"
import { Icon, Separator } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Tooltip } from "@/design-system/Tooltip"
import {
  SellFlowMultiStepModalContent,
  MultichainListingModalContent,
} from "@/features/sell/components/SellFlowMultiStepModal/SellFlowMultiStepModal.react"
import {
  useSellFlow,
  UseSellFlow,
} from "@/features/sell/hooks/useSellFlow.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackQuickSingleListingModalOpen,
  trackQuickSingleListingModalClickOpenFullView,
  trackQuickSingleListingModalClose,
  trackQuickSingleListingModalSubmitted,
} from "@/lib/analytics/events/itemEvents"
import { CreateQuickSingleListingFlowModal_asset$key } from "@/lib/graphql/__generated__/CreateQuickSingleListingFlowModal_asset.graphql"
import {
  CreateQuickSingleListingFlowModalQuery,
  CreateQuickSingleListingFlowModalQuery$data,
} from "@/lib/graphql/__generated__/CreateQuickSingleListingFlowModalQuery.graphql"
import { getItemSellUrl } from "@/lib/helpers/item"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { captureNoncriticalError } from "@/lib/sentry"
import { QuickListingFlowModalErrorContent } from "./QuickListingFlowModalErrorContent.react"
import { QuickListingItemDetails } from "./QuickListingItemDetails.react"

type Props = {
  close: () => unknown
  asset: CreateQuickSingleListingFlowModal_asset$key
  initialQuantity?: BigNumber
  onSubmitted?: (orderItemRelayId?: string) => unknown
}

export const CreateQuickSingleListingFlowModal = ({
  asset: assetDataKey,
  close,
  initialQuantity,
  onSubmitted,
}: Props) => {
  const asset = useFragment<CreateQuickSingleListingFlowModal_asset$key>(
    graphql`
      fragment CreateQuickSingleListingFlowModal_asset on AssetType {
        relayId
        chain {
          identifier
        }
        ...itemEvents_dataV2
      }
    `,
    assetDataKey,
  )
  useEffect(() => {
    trackQuickSingleListingModalOpen(asset)
    return () => {
      trackQuickSingleListingModalClose(asset)
    }
  }, [asset])

  const trackSubmit = () => {
    trackQuickSingleListingModalSubmitted(asset)
  }

  const trackOpenInFullView = () => {
    trackQuickSingleListingModalClickOpenFullView(asset)
  }

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error)
        return <QuickListingFlowModalErrorContent onClose={close} />
      }}
    >
      <SsrSuspense fallback={<Loading />}>
        <LazyCreateQuickSingleListingFlow
          assetRelayId={asset.relayId}
          chainIdentifier={asset.chain.identifier}
          close={close}
          initialQuantity={initialQuantity}
          trackOpenInFullView={trackOpenInFullView}
          trackSubmit={trackSubmit}
          onSubmitted={onSubmitted}
        />
      </SsrSuspense>
    </ErrorBoundary>
  )
}

type CreateQuickSingleListingFlowProps = {
  close: () => unknown
  assetRelayId: string
  chainIdentifier: ChainIdentifier
  initialQuantity?: BigNumber
  trackSubmit: () => void
  trackOpenInFullView: () => void
  onSubmitted?: (orderItemRelayId?: string) => unknown
}

const LazyCreateQuickSingleListingFlow = ({
  assetRelayId,
  close,
  chainIdentifier,
  initialQuantity,
  trackSubmit,
  trackOpenInFullView,
  onSubmitted,
}: CreateQuickSingleListingFlowProps) => {
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPaymentAssetRelayId, setSelectedPaymentAssetRelayId] =
    useState("")
  const [price, setPrice] = useState(0)

  const { asset, tradeLimits } =
    useLazyLoadQuery<CreateQuickSingleListingFlowModalQuery>(
      graphql`
        query CreateQuickSingleListingFlowModalQuery(
          $chain: ChainScalar!
          $assetRelayId: AssetRelayID!
        ) {
          tradeLimits(chain: $chain) {
            ...useSellFlow_tradeLimits
          }
          asset(asset: $assetRelayId) {
            ...item_sellUrl
            ...QuickListingItemDetails_asset
            # eslint-disable-next-line relay/must-colocate-fragment-spreads - needed due to implementation of sellPageQueries_asset in useSellFlow
            ...sellPageQueries_asset @arguments(identity: {})
            collection {
              paymentAssets(chain: $chain) {
                # eslint-disable-next-line relay/must-colocate-fragment-spreads - needed due to implementation of sellPageQueries_paymentAssets in useSellFlow
                ...sellPageQueries_paymentAssets
              }
            }
            chain {
              ...useSellFlow_chain
            }
          }
        }
      `,
      {
        chain: chainIdentifier,
        assetRelayId,
      },
    )

  useEffect(() => {
    if (isSubmitting) {
      trackSubmit()
    }
  }, [isSubmitting, trackSubmit])

  const sellFlow = useSellFlow({
    assetDataKeys: [asset],
    defaultFormValues: {
      quantity: initialQuantity?.toString(),
    },
    paymentAssetsDataKey: asset.collection.paymentAssets,
    selectedPaymentAssetRelayId,
    setPrice,
    setSelectedPaymentAssetRelayId,
    tradeLimitsDataKey: tradeLimits,
    submitRef,
    isSubmitting,
    setIsSubmitting,
    isDisplayedInModal: true,
    chain: asset.chain,
  })

  if (!sellFlow) {
    return <QuickListingFlowModalErrorContent onClose={close} />
  }

  return (
    <CreateQuickSingleListingFlowModalContent
      asset={asset}
      close={close}
      isSubmitting={isSubmitting}
      price={bn(price)}
      selectedPaymentAssetRelayId={selectedPaymentAssetRelayId}
      sellFlow={sellFlow}
      submitRef={submitRef}
      trackOpenInFullView={trackOpenInFullView}
      onSubmitted={onSubmitted}
    />
  )
}

type CreateQuickSingleListingFlowModalContentProps = {
  sellFlow: UseSellFlow
  submitRef?: React.MutableRefObject<HTMLButtonElement | null>
  close: () => unknown
  asset: CreateQuickSingleListingFlowModalQuery$data["asset"]
  price: BigNumber
  isSubmitting: boolean
  onSubmitted?: (orderItemRelayId?: string) => unknown
  selectedPaymentAssetRelayId: string
  trackOpenInFullView: () => void
}

const CreateQuickSingleListingFlowModalContent = ({
  sellFlow,
  close,
  submitRef,
  asset,
  price,
  isSubmitting,
  selectedPaymentAssetRelayId,
  onSubmitted,
  trackOpenInFullView,
}: CreateQuickSingleListingFlowModalContentProps) => {
  const t = useTranslate("sell")
  const { onNext } = useMultiStepFlowContext()
  const { listingModalProps, renderForm, watchedFormState } = sellFlow
  const renderListingModal = useCallback(() => {
    return (
      <MultichainListingModalContent
        {...listingModalProps}
        onClose={close}
        onSubmitted={onSubmitted}
      />
    )
  }, [close, listingModalProps, onSubmitted])

  const handleConfirm = () => submitRef?.current?.click()
  const onOpenInFullView = () => {
    trackOpenInFullView()
    close()
  }

  useEffect(() => {
    if (listingModalProps.submittedFormData) {
      onNext(() => (
        <SellFlowMultiStepModalContent
          close={close}
          getAggregatedPriceWarningInfo={
            listingModalProps.getAggregatedPriceWarningInfo
          }
          renderListingModal={renderListingModal}
        />
      ))
    }
  }, [
    listingModalProps.submittedFormData,
    listingModalProps.getAggregatedPriceWarningInfo,
    onNext,
    close,
    renderListingModal,
  ])
  const { fieldValues, dirtyFields } = watchedFormState

  const duration = fieldValues.duration
  const quantity = fieldValues.quantity

  const isMultiQuantity =
    quantity !== undefined && bn(quantity).isGreaterThan(1)

  return (
    <>
      <Modal.Header>
        <Flex alignItems="center" className="relative w-full">
          <Modal.Header.Title>
            {t("createQuickSingleListingModal.quickList.title", "Quick list")}
          </Modal.Header.Title>
          <Link
            className="absolute right-10 top-0 flex"
            href={getItemSellUrl(asset, {
              price: price.toString() !== "0" ? price.toString() : undefined,
              paymentAssetId: selectedPaymentAssetRelayId,
              duration: dirtyFields.duration ? duration : undefined,
              quantity: isMultiQuantity ? quantity : undefined,
            })}
            target="_blank"
            onClick={onOpenInFullView}
          >
            <Tooltip
              content={t(
                "createQuickSingleListingModal.openFullView",
                "Open full view",
              )}
            >
              <span>
                <OpenInNewTabIcon value="open_in_new" />
              </span>
            </Tooltip>
          </Link>
        </Flex>
      </Modal.Header>
      <ModalBody>
        <QuickListingItemDetails
          asset={asset}
          paymentAssetsDataKey={asset.collection.paymentAssets}
          price={price}
          selectedPaymentAssetRelayId={selectedPaymentAssetRelayId}
        />
        <Separator className="mb-[18px] mr-0 mt-2.5" />
        {renderForm()}
      </ModalBody>
      <Modal.Footer>
        <Modal.Footer.Button
          disabled={isSubmitting || watchedFormState.disabled}
          isLoading={isSubmitting}
          onClick={handleConfirm}
        >
          {t("createQuickSingleListingModal.confirm", "Complete listing")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}

const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
  @media (min-height: 768px) {
    max-height: 75vh;
  }
`

const OpenInNewTabIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};
  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }
`
const Loading = styled(ModalLoader)`
  height: 600px;
  @media (min-height: 768px) {
    max-height: 75vh;
  }
`
