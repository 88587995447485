/**
 * @generated SignedSource<<59b437848c11fe98b44a7f6a9ec26610>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useIsGaslessCancellationEnabled_order$data = {
  readonly orderType: OrderV2OrderType;
  readonly side: OrderV2Side | null;
  readonly " $fragmentType": "useIsGaslessCancellationEnabled_order";
};
export type useIsGaslessCancellationEnabled_order$key = {
  readonly " $data"?: useIsGaslessCancellationEnabled_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsGaslessCancellationEnabled_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsGaslessCancellationEnabled_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "ca200ce881760639dfd93eb1b8415c0c";

export default node;
