/**
 * @generated SignedSource<<a4a48a8154b42164097de00aad2b3ad8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickListingItemDetails_asset$data = {
  readonly assetContract: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
  };
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
  };
  readonly defaultRarityData: {
    readonly rank: number;
  } | null;
  readonly displayName: string;
  readonly isCurrentlyFungible: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  readonly " $fragmentType": "QuickListingItemDetails_asset";
};
export type QuickListingItemDetails_asset$key = {
  readonly " $data"?: QuickListingItemDetails_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickListingItemDetails_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickListingItemDetails_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentlyFungible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RarityDataType",
      "kind": "LinkedField",
      "name": "defaultRarityData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionLink_collection"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionLink_assetContract"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StackedAssetMedia_assets"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "50b85b929bee001b3220b502f282415d";

export default node;
