/**
 * @generated SignedSource<<55cbd5cbdc937496cf32b4060b69b62a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditListingButton_listing$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditListingModal_listing">;
  readonly " $fragmentType": "EditListingButton_listing";
};
export type EditListingButton_listing$key = {
  readonly " $data"?: EditListingButton_listing$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditListingButton_listing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditListingButton_listing",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditListingModal_listing"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "982ccaf9e94af5b336e4ecc50fc8a6cc";

export default node;
