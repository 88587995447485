import React, { useCallback } from "react"
import { Icon, UnstyledButton } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { Source } from "@/components/trade/analytics"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useCanceledListingRelayIds } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useTranslate } from "@/hooks/useTranslate"
import { trackEditListingButton } from "@/lib/analytics/events/itemEvents"
import { EditListingButton_item$key } from "@/lib/graphql/__generated__/EditListingButton_item.graphql"
import { EditListingButton_listing$key } from "@/lib/graphql/__generated__/EditListingButton_listing.graphql"
import { EditListingModal } from "./EditListingModal.react"

type Props = {
  isIcon?: boolean
  className?: string
  item: EditListingButton_item$key
  listing: EditListingButton_listing$key
  source: Source
  onReviewListings?: () => unknown
  onOrdersChanged?: () => unknown
  trigger?: (onClick: () => unknown) => React.ReactNode
} & Omit<ButtonProps, "href" | "onClick" | "order">

export const EditListingButton = ({
  className,
  item: itemKey,
  listing: listingKey,
  source,
  onReviewListings,
  onOrdersChanged,
  trigger,
  isIcon = false,
  ...buttonProps
}: Props) => {
  const t = useTranslate("components")
  const { openModal } = useGlobalModal()
  const [_, setCanceledListingRelayIds] = useCanceledListingRelayIds()
  const item = useFragment(
    graphql`
      fragment EditListingButton_item on ItemType {
        chain {
          identifier
        }
        ...EditListingModal_item
        ...itemEvents_dataV2
      }
    `,
    itemKey,
  )

  const listing = useFragment(
    graphql`
      fragment EditListingButton_listing on OrderV2Type {
        ...EditListingModal_listing
      }
    `,
    listingKey,
  )

  const onListingsCanceled = useCallback(
    (listingRelayIds: string[]) => {
      setCanceledListingRelayIds(prevCanceledRelayIds =>
        prevCanceledRelayIds.concat(listingRelayIds),
      )
    },
    [setCanceledListingRelayIds],
  )

  const handleOnClick = useCallback(() => {
    trackEditListingButton(item, {
      source,
    })
    openModal(
      close => (
        <EditListingModal
          item={item}
          listing={listing}
          source={source}
          onClose={close}
          onListingsCanceled={onListingsCanceled}
          onOrdersChanged={onOrdersChanged}
          onReviewListings={onReviewListings}
        />
      ),
      {
        position: "centered",
        size: "medium",
      },
    )
  }, [
    item,
    listing,
    onListingsCanceled,
    onOrdersChanged,
    onReviewListings,
    openModal,
    source,
  ])

  const defaultLabel = t("orderManager.editListing.button", "Edit listing")

  return (
    <NetworkUnsupportedGate
      chainIdentifier={item.chain.identifier}
      shouldAuthenticate
    >
      {({ handleIfNotSupported }) =>
        trigger ? (
          trigger(handleIfNotSupported(handleOnClick))
        ) : isIcon ? (
          <Tooltip content={defaultLabel}>
            <UnstyledButton
              className={className}
              style={{ padding: 2 }}
              onClick={e => {
                e.stopPropagation()
                return handleIfNotSupported(handleOnClick)()
              }}
            >
              <Icon aria-label={defaultLabel} value="edit" />
            </UnstyledButton>
          </Tooltip>
        ) : (
          <Button
            className={className}
            onClick={handleIfNotSupported(handleOnClick)}
            {...buttonProps}
          >
            {defaultLabel}
          </Button>
        )
      }
    </NetworkUnsupportedGate>
  )
}
