/**
 * @generated SignedSource<<fdad56a6a5c2add1ce4ec34e7544c753>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditListingModal_listing$data = {
  readonly relayId: string;
  readonly " $fragmentType": "EditListingModal_listing";
};
export type EditListingModal_listing$key = {
  readonly " $data"?: EditListingModal_listing$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditListingModal_listing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditListingModal_listing",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "82f81130026403c26f9b4d34c8640dd0";

export default node;
