import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { Item, ItemProps } from "@/design-system/Item"
import { useTranslate } from "@/hooks/useTranslate"
import { EditListingItemDetails_order$key } from "@/lib/graphql/__generated__/EditListingItemDetails_order.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { AssetMedia } from "../assets/AssetMedia"
import { ExpirationDate } from "./ExpirationDate"

type EditListingItemDetailsProps = {
  orderDataKey: EditListingItemDetails_order$key | null
} & ItemProps

export const EditListingItemDetails = ({
  orderDataKey,
  ...itemProps
}: EditListingItemDetailsProps) => {
  const t = useTranslate("orders")
  const order = useFragment(
    graphql`
      fragment EditListingItemDetails_order on OrderV2Type {
        item {
          __typename

          ... on AssetType {
            displayName
            isCurrentlyFungible
            defaultRarityData {
              rank
            }
            ...AssetMedia_asset
          }
        }
        remainingQuantityType
        ...OrderPrice
        ...OrderUsdPrice
        ...ExpirationDate_data
      }
    `,
    orderDataKey,
  )

  const item = order?.item

  if (!item || item.__typename === "%other") {
    return null
  }

  const isAssetTypeCurrentlyFungible = item.isCurrentlyFungible

  return (
    <Item
      {...itemProps}
      className={classNames("border-0", itemProps.className)}
    >
      <ItemQuantityBadge
        overrides={{
          QuantityBadge: { props: { className: classNames("right-3") } },
        }}
        quantity={order.remainingQuantityType}
      >
        <Item.Avatar size={84}>
          <AssetMedia asset={item} />
        </Item.Avatar>
      </ItemQuantityBadge>
      <Item.Content>
        <Item.Title
          className="block"
          data-testid="EditListingItemDetails--name"
          size="medium"
          weight="semibold"
        >
          {item.displayName}
        </Item.Title>

        <Item.Description>
          {t("editListing.expiration", "Expires {{time}}", {
            time: (
              <ExpirationDate
                alwaysRelative
                className="text-secondary"
                dataKey={order}
              />
            ),
          })}
        </Item.Description>

        {item.defaultRarityData && (
          <Item.Description className="text-secondary" size="small">
            {t("rarityRank", "Rarity #{{rarityRank}}", {
              rarityRank: item.defaultRarityData.rank,
            })}
          </Item.Description>
        )}
      </Item.Content>
      <Item.Side className="max-w-full">
        <Item.Description>
          <Text className="flex justify-end text-secondary" size="small">
            {isAssetTypeCurrentlyFungible
              ? t(
                  "editListingItemDetails.listingPricePerItem",
                  "Listing price per item",
                )
              : t("editListingItemDetails.listingPrice", "Listing price")}
          </Text>
        </Item.Description>
        <Item.Title>
          <Text
            className="flex justify-end"
            data-testid="AcceptOfferModalContent--total-price"
            size="medium"
            weight="semibold"
          >
            <OrderPrice
              order={order}
              variant={isAssetTypeCurrentlyFungible ? "perUnit" : "total"}
            />
          </Text>
        </Item.Title>
        <Item.Description>
          <Text className="flex justify-end" size="small">
            <OrderUsdPrice
              order={order}
              secondary
              variant={isAssetTypeCurrentlyFungible ? "perUnit" : "total"}
            />
          </Text>
        </Item.Description>
      </Item.Side>
    </Item>
  )
}
