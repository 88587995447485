import { Environment, commitLocalUpdate } from "react-relay"

export const removeLocalItemBestAsk = (
  environment: Environment,
  itemRelayIds: string[],
) => {
  commitLocalUpdate(environment, store => {
    itemRelayIds.forEach(itemRelayId => {
      const itemRecord = store.get(itemRelayId)
      itemRecord?.getLinkedRecord("orderData")?.setValue(null, "bestAskV2")
    })
  })
}
