import React from "react"
import { Flex, FlexProps, classNames } from "@opensea/ui-kit"
import { merge } from "lodash"
import { transparentize } from "polished"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import {
  ItemAddToCartButton,
  ItemAddToCartButtonProps,
} from "@/features/shopping-cart/components/ItemAddToCartButton"
import { AddToCartAndQuickBuyButton_order$key } from "@/lib/graphql/__generated__/AddToCartAndQuickBuyButton_order.graphql"
import { QuickBuyButton, QuickBuyButtonProps } from "../QuickBuyButton"
import { useIsQuickBuyEnabled } from "../QuickBuyButton/useIsQuickBuyEnabled.react"

type Props = {
  order: AddToCartAndQuickBuyButton_order$key
  overrides?: {
    ItemAddToCartButton?: {
      props: Partial<Omit<ItemAddToCartButtonProps, "order">>
    }
    QuickBuyButton?: {
      props: Partial<Omit<QuickBuyButtonProps, "order">>
    }
  }
} & FlexProps

const QUICK_CART_BUTTON_WIDTH = 56

export const AddToCartAndQuickBuyButton = ({
  className,
  order: orderDataKey,
  overrides,
  ...blockProps
}: Props) => {
  const order = useFragment(
    graphql`
      fragment AddToCartAndQuickBuyButton_order on OrderV2Type {
        ...useIsQuickBuyEnabled_order
        ...ItemAddToCartButton_order
        ...QuickBuyButton_order
      }
    `,
    orderDataKey,
  )

  const isQuickBuyEnabled = useIsQuickBuyEnabled(order)

  if (!isQuickBuyEnabled) {
    return (
      <Block {...blockProps}>
        <ItemAddToCartButton
          order={order}
          {...overrides?.ItemAddToCartButton?.props}
        />
      </Block>
    )
  }

  return (
    <Flex
      {...blockProps}
      className={classNames("relative overflow-hidden rounded-xl", className)}
    >
      <StyledQuickBuyButton
        order={order}
        {...overrides?.QuickBuyButton?.props}
        overrides={merge(
          {
            Button: {
              props: {
                style: { borderBottomRightRadius: 0, borderTopRightRadius: 0 },
              },
            },
          },

          overrides?.QuickBuyButton?.props.overrides,
        )}
      />

      <StyledItemAddToCartButton
        iconOnly
        order={order}
        {...overrides?.ItemAddToCartButton?.props}
        overrides={merge(
          {
            Button: {
              props: {
                style: {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
                overrides: { Icon: { props: { size: 24 } } },
              },
            },
          },

          overrides?.ItemAddToCartButton?.props.overrides,
        )}
      />
    </Flex>
  )
}

const StyledItemAddToCartButton = styled(
  ItemAddToCartButton,
)<ItemAddToCartButtonProps>`
  border-left: 1px solid
    ${props => transparentize(0.4, props.theme.colors.white)};

  position: absolute;
  right: 0;
  height: 100%;
  width: ${QUICK_CART_BUTTON_WIDTH}px;
  white-space: nowrap;
`

const StyledQuickBuyButton = styled(QuickBuyButton)<QuickBuyButtonProps>`
  width: calc(100% - ${QUICK_CART_BUTTON_WIDTH - 1}px);
  white-space: nowrap;
`
