import React, { Suspense, useCallback, useEffect } from "react"
import { Media, Text, Switch, SpaceBetween } from "@opensea/ui-kit"
import { differenceInMinutes, intlFormat } from "date-fns"
import { Controller } from "react-hook-form"
import { useFragment, useLazyLoadQuery, useRelayEnvironment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { BlockchainActionOnEnd } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { EditListingActionModal } from "@/components/blockchain/orders/EditListingActionModal"
import { Link } from "@/components/common/Link"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { removeLocalItemBestAsk } from "@/components/orders/utils"
import { Source } from "@/components/trade/analytics"
import { PaymentTokenInput } from "@/components/trade/PaymentTokenInput"
import { mapPaymentAssetOption } from "@/components/trade/PaymentTokenInput/utils"
import { Block } from "@/design-system/Block"
import { FormControl } from "@/design-system/FormControl"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { CancelOrdersActionModal } from "@/features/cancel-orders/components/CancelOrdersActionModal/CancelOrdersActionModal.react"
import { useIsGaslessCancellationEnabled } from "@/features/cancel-orders/hooks/useIsGaslessCancellationEnabled"
import { CreatorFeeShowSupport } from "@/features/creator-fees/components/CreatorFeeShowSupport"
import { CreatorFeeToggleGroup } from "@/features/creator-fees/components/CreatorFeeToggleGroup"
import { trackDurationOptionSubmit } from "@/features/orders/analytics/OrderDurationSelectTracker"
import { OrderDurationSelect } from "@/features/orders/components/OrderDurationSelect.react"
import {
  DateRange,
  DateRangeOptionsVariant,
  useDateRangeOptions,
} from "@/features/orders/hooks/useDateRangeOptions"
import { CollectionPriceButtons } from "@/features/sell/components/CollectionPriceButtons"
import {
  PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD,
  PriceWarningModal,
} from "@/features/sell/components/SellFlowMultiStepModal/PriceWarningModal.react"
import { WarningSeverity } from "@/features/sell/hooks/useSellFlow.react"
import { useForm } from "@/hooks/useForm"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackOptionalCreatorFeeHidden,
  trackOptionalCreatorFeeShown,
  trackOptionalCreatorFeeSubmitted,
} from "@/lib/analytics/events/feeEvents"
import { trackEditListingSubmitted } from "@/lib/analytics/events/itemEvents"
import { EditListingModal_item$key } from "@/lib/graphql/__generated__/EditListingModal_item.graphql"
import { EditListingModal_listing$key } from "@/lib/graphql/__generated__/EditListingModal_listing.graphql"
import { EditListingModalQuery } from "@/lib/graphql/__generated__/EditListingModalQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { isSolana } from "@/lib/helpers/chainUtils"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { getItemUrl } from "@/lib/helpers/item"
import {
  basisPointsToPercentage,
  BigNumber,
  bn,
  normalizePriceDisplay,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import { isValidNumberWithDecimals } from "@/lib/helpers/validation"
import { EditListingItemDetails } from "./EditListingItemDetails.react"
import { LowerPriceListingWarningModal } from "./LowerPriceListingWarningModal.react"

type FormData = {
  newAmount: string
  newExpirationDate?: DateRange
  isCustomExpiration: boolean
  totalCreatorFeePercentage: string
}

type Props = {
  item: EditListingModal_item$key
  listing: EditListingModal_listing$key
  source: Source
  onClose: () => unknown
  onReviewListings?: () => unknown
  onOrdersChanged?: () => unknown
  onListingsCanceled?: (listingRelayIds: string[]) => unknown
}

const EDIT_LISTING_MODAL_QUERY = graphql`
  query EditListingModalQuery(
    $listing: OrderRelayID!
    $asset: ArchetypeInputType
  ) {
    assetOrders: orders(
      first: 20
      isValid: true
      isExpired: false
      maker: {}
      makerArchetype: $asset
      sortAscending: true
      sortBy: PRICE
      takerAssetIsPayment: true
    ) {
      edges {
        node {
          relayId
          item {
            relayId
          }
        }
      }
    }

    listing: order(order: $listing) {
      closedAt
      relayId
      priceType {
        unit
      }
      item {
        __typename
        relayId
        chain {
          identifier
        }
        ... on AssetType {
          isCurrentlyFungible
          totalCreatorFee
          collection {
            isCreatorFeesEnforced
            totalCreatorFeeBasisPoints
            statsV2 {
              floorPrice {
                usd
              }
            }
          }
          ...CollectionPriceButtons_asset
        }
        ...itemEvents_dataV2
        ...item_url
      }
      payment {
        relayId
        symbol
        usdPrice
        ...utils_PaymentAssetOption
      }
      orderType
      remainingQuantityType
      ...EditListingItemDetails_order
    }
  }
`

const ORDER_DURATION_SELECT_VARIANT: DateRangeOptionsVariant = "quick-listing"

const EditListingModalBase = ({
  item: itemKey,
  listing: listingKey,
  source,
  onClose,
  onReviewListings,
  onOrdersChanged,
  onListingsCanceled,
}: Props) => {
  const t = useTranslate("components")
  const { showSuccessMessage, showErrorMessage } = useToasts()
  const { defaultEndDate } = useDateRangeOptions(ORDER_DURATION_SELECT_VARIANT)

  const itemIdentifier = useFragment(
    graphql`
      fragment EditListingModal_item on ItemType {
        __typename
        ... on AssetType {
          tokenId
          assetContract {
            address
          }
          chain {
            identifier
          }
        }
      }
    `,
    itemKey,
  )

  const listingIdentifier = useFragment(
    graphql`
      fragment EditListingModal_listing on OrderV2Type {
        relayId
      }
    `,
    listingKey,
  )

  const isAsset = itemIdentifier.__typename === "AssetType"

  const { assetOrders, listing } = useLazyLoadQuery<EditListingModalQuery>(
    EDIT_LISTING_MODAL_QUERY,
    {
      listing: listingIdentifier.relayId,
      asset: isAsset
        ? {
            assetContractAddress: itemIdentifier.assetContract.address,
            tokenId: itemIdentifier.tokenId,
            chain: itemIdentifier.chain.identifier,
          }
        : undefined,
    },
  )

  const cancelableListings = getNodes(assetOrders)

  const collection = listing.item.collection
  const collectionFloorPrice = collection?.statsV2.floorPrice
  const maxBasisPoints =
    collection?.totalCreatorFeeBasisPoints || listing.item.totalCreatorFee || 0
  const chain = listing.item.chain.identifier
  const isAssetTypeCurrentlyFungible = listing.item.isCurrentlyFungible

  const shouldShowOptionalCreatorFee =
    !collection?.isCreatorFeesEnforced && maxBasisPoints > 0

  useEffect(() => {
    if (shouldShowOptionalCreatorFee) {
      trackOptionalCreatorFeeShown({
        source: "edit-listing-modal",
        isMaxFees: true,
        optionalBasisPoints: maxBasisPoints,
        maxBasisPoints,
        percent: 100,
      })
    } else {
      trackOptionalCreatorFeeHidden({ source: "edit-listing-modal" })
    }
  }, [maxBasisPoints, shouldShowOptionalCreatorFee])

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    control,
    watch,
    setValue,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      isCustomExpiration: false,
      totalCreatorFeePercentage: basisPointsToPercentage(maxBasisPoints),
    },
  })
  const { onReplace, onNext } = useMultiStepFlowContext()
  const newAmount = watch("newAmount")
    ? bn(watch("newAmount"))
        .multipliedBy(listing.remainingQuantityType)
        .toString()
    : ""
  const isCustomExpiration = watch("isCustomExpiration")
  const totalCreatorFeePercentage = watch("totalCreatorFeePercentage")

  const previousAmount = bn(listing.priceType.unit)

  const previousExpiration = listing.closedAt
    ? dateFromISO8601(listing.closedAt)
    : undefined

  const paymentAssetOption = mapPaymentAssetOption(listing.payment)

  const getPercentageBelowFloorPrice = (): BigNumber => {
    if (!collectionFloorPrice || !paymentAssetOption.usdSpotPrice) {
      return bn(0)
    }

    const priceUsd = bn(newAmount).multipliedBy(paymentAssetOption.usdSpotPrice)
    if (priceUsd.isGreaterThan(collectionFloorPrice.usd)) {
      return bn(0)
    }

    return BigNumber.min(
      bn(1).minus(priceUsd.div(collectionFloorPrice.usd)),
      0.99,
    ).multipliedBy(100)
  }
  const percentageBelowFloorPrice = getPercentageBelowFloorPrice()
  const isBelowFloorPrice = percentageBelowFloorPrice.isGreaterThan(0)
  const showHighSeverity = percentageBelowFloorPrice.isGreaterThan(
    PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD,
  )

  const environment = useRelayEnvironment()
  const onCancelAllListings = useCallback(() => {
    const itemRelayIds = cancelableListings.map(listing => listing.item.relayId)
    const listingRelayIds = cancelableListings.map(listing => listing.relayId)
    removeLocalItemBestAsk(environment, itemRelayIds)
    onOrdersChanged?.()
    onListingsCanceled?.(listingRelayIds)
  }, [environment, cancelableListings, onOrdersChanged, onListingsCanceled])

  const onEditListing = async ({
    newAmount,
    newExpirationDate,
    totalCreatorFeePercentage,
  }: FormData) => {
    if (
      bn(newAmount).isLessThanOrEqualTo(0) &&
      !window.confirm(
        t(
          "editListingModal.confirm.title",
          "Are you sure you want to make this free?",
        ),
      )
    ) {
      setError("newAmount", {
        type: "greaterThanZero",
        message: t(
          "editListingModal.error.message",
          "Please try again with a positive price",
        ),
      })
      return
    }

    if (shouldShowOptionalCreatorFee && totalCreatorFeePercentage) {
      const totalCreatorFeeBasisPoints = percentageToBasisPoints(
        totalCreatorFeePercentage,
      )
      trackOptionalCreatorFeeSubmitted({
        source: "edit-listing-modal",
        isMaxFees: totalCreatorFeeBasisPoints === maxBasisPoints,
        optionalBasisPoints: totalCreatorFeeBasisPoints,
        maxBasisPoints,
        percent: totalCreatorFeeBasisPoints / maxBasisPoints,
      })
    }

    const submit = async () => {
      try {
        const amount = bn(newAmount).multipliedBy(listing.remainingQuantityType)

        const onEditListingSuccess: BlockchainActionOnEnd = ({
          transaction,
          createdOrder,
        } = {}) => {
          trackEditListingSubmitted(listing.item, {
            previousAmount: +previousAmount,
            newAmount: +amount,
            source,
          })
          if (newExpirationDate) {
            trackDurationOptionSubmit({
              durationMinutes: differenceInMinutes(
                newExpirationDate.end,
                new Date(),
              ),
              variant: ORDER_DURATION_SELECT_VARIANT,
            })
          }
          showSuccessMessage(
            t(
              "orderManager.listingEdited.success",
              "Price successfully changed",
            ),
          )

          if (previousAmount.gt(amount)) {
            onReplace(
              <LowerPriceListingWarningModal
                onClose={onClose}
                onReviewListings={onReviewListings}
              />,
            )
          } else {
            onReplace(
              <AssetSuccessModalContent
                itemName={createdOrder?.item.displayName ?? undefined}
                itemUrl={getItemUrl(listing.item)}
                mode="listed"
                transaction={transaction}
                variables={{
                  assetIDs: [listing.item.relayId],
                }}
                onClose={onClose}
              />,
            )
          }
          onOrdersChanged?.()
        }

        const errorCopy = t(
          "orderManager.editListing.error",
          "Something went wrong while editing your listing",
        )

        onNext(
          <EditListingActionModal
            closedAt={newExpirationDate?.end.toISOString()}
            newPrice={{
              paymentAsset: listing.payment.relayId,
              amount: amount.toString(),
            }}
            optionalCreatorFeeBasisPoints={percentageToBasisPoints(
              totalCreatorFeePercentage,
            )}
            orderId={listing.relayId}
            onEnd={onEditListingSuccess}
            onError={(error?: Error) => {
              showErrorMessage(error?.message || errorCopy)
            }}
          />,
        )
      } catch (error) {
        onClose()
      }
    }

    if (showHighSeverity) {
      onNext(
        <PriceWarningModal
          priceWarningActionMessage={t(
            "editListingModal.confirm.message",
            "Confirm listing",
          )}
          priceWarningHeader={t(
            "editListingModal.confirmLow.message",
            "Confirm low listing price?",
          )}
          priceWarningMessage={
            <Block>
              {t(
                "editListingModal.priceWarning.message",
                "Your updated listing will be {{price}} below the floor price for this collection.",
                {
                  price: (
                    <b>{`${normalizePriceDisplay(
                      percentageBelowFloorPrice,
                      0,
                    )}%`}</b>
                  ),
                },
              )}
            </Block>
          }
          onClose={onClose}
          onConfirm={submit}
        />,
      )
      return
    }

    await submit()
  }

  const floorPriceDifference = () => {
    return isBelowFloorPrice ? (
      <BelowFloorPriceText
        warningSeverity={
          showHighSeverity ? WarningSeverity.High : WarningSeverity.Low
        }
      >
        {t(
          "editListingModal.floorPrice.warning",
          "This amount is below the collection floor price. Consider a higher price.",
        )}
      </BelowFloorPriceText>
    ) : undefined
  }
  const creatorFeeInputProps = useOptionalCreatorFeeInput({ maxBasisPoints })

  const { isEnabledForListings } = useIsGaslessCancellationEnabled()

  const footerButtons = (
    <>
      <Modal.Footer.Button
        className="mb-2"
        variant="destructive"
        onClick={() => {
          onReplace(
            <CancelOrdersActionModal
              gaslessCancelEligibleOrders={isEnabledForListings}
              orders={
                // Solana can only cancel one order currently
                isSolana(chain) && listing.item.relayId
                  ? [listing.relayId]
                  : cancelableListings.map(({ relayId }) => relayId)
              }
              onEnd={() => {
                onClose()
                onCancelAllListings()
              }}
              onError={(error?: Error) => {
                showErrorMessage(error?.message)
                onClose()
              }}
            />,
          )
        }}
      >
        {t("orderManager.button.cancelAllListings", "Cancel all listings")}
      </Modal.Footer.Button>
      <Modal.Footer.Button
        className="mb-2"
        disabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        type="submit"
      >
        {t("editListingModal.button.continue", "Continue")}
      </Modal.Footer.Button>
    </>
  )

  const totalCreatorFeePercentageError =
    totalCreatorFeePercentage && errors.totalCreatorFeePercentage?.message

  return (
    <Modal.Form onSubmit={handleSubmit(onEditListing)}>
      <Modal.Header>
        <Modal.Header.Title>
          {t("editListingModal.modal.title", "Edit listing")}
        </Modal.Header.Title>
      </Modal.Header>

      <Modal.Body maxHeight={{ _: "75vh", sm: "auto" }}>
        <EditListingItemDetails className="p-0 pb-6" orderDataKey={listing} />
        <FormControl
          label={
            isAssetTypeCurrentlyFungible
              ? t(
                  "editListingModal.pricePerItem.label",
                  "Set new price per item",
                )
              : t("editListingModal.price.label", "Set new price")
          }
          tip={
            <>
              <Text.Body className="text-secondary" size="small">
                {t(
                  "editListingModal.increasePriceDisclaimer.body",
                  "If you want to increase the price, you will be prompted to cancel all of your existing listings first. This will cost gas. {{link}}.",
                  {
                    link: (
                      <Link
                        href="https://support.opensea.io/articles/8867007"
                        target="_blank"
                      >
                        {t(
                          "editListingModal.cancelListing.learnMore",
                          "Learn more",
                        )}
                      </Link>
                    ),
                  },
                )}
              </Text.Body>
              {isAsset && (
                <CollectionPriceButtons
                  asset={listing.item}
                  priceConversion={{
                    usdPrice: bn(listing.payment.usdPrice),
                    symbol: listing.payment.symbol,
                  }}
                  variant="compact"
                  onClick={({ unit }) => {
                    setValue("newAmount", unit.toString(), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }}
                />
              )}
            </>
          }
        >
          <Controller
            control={control}
            name="newAmount"
            render={({ field }) => (
              <PaymentTokenInput
                autoFocus
                error={errors[field.name]}
                id={field.name}
                name={field.name}
                paymentAssetOptions={[paymentAssetOption]}
                paymentAssetRelayId={paymentAssetOption.value}
                placeholder={t("editListingModal.price.placeholder", "Price")}
                price={field.value}
                warning={floorPriceDifference()}
                onChange={field.onChange}
              />
            )}
            rules={{
              validate: {
                ...isValidNumberWithDecimals({
                  maxDecimals: paymentAssetOption.decimals,
                }),
                isEnglishAuction: () =>
                  listing.orderType !== "ENGLISH" ||
                  t(
                    "priceDropModal.editPrice.warning",
                    "You cannot change the price of an English auction listing. Please cancel and create a new listing.",
                  ),
              },
            }}
          />
        </FormControl>

        {shouldShowOptionalCreatorFee && (
          <Block marginTop="24px">
            <FormControl
              error={totalCreatorFeePercentageError}
              label={creatorFeeInputProps.label}
            >
              <CreatorFeeToggleGroup
                control={control}
                error={Boolean(totalCreatorFeePercentageError)}
                maxBasisPoints={maxBasisPoints}
                name="totalCreatorFeePercentage"
                price={
                  newAmount
                    ? {
                        unit: newAmount,
                        usd: listing.payment.usdPrice,
                        symbol: listing.payment.symbol,
                      }
                    : undefined
                }
              />
            </FormControl>
            <Block marginTop="16px">
              <CreatorFeeShowSupport basisPoints={maxBasisPoints} />
            </Block>
          </Block>
        )}

        <Block marginTop="24px">
          <Controller
            control={control}
            name="newExpirationDate"
            render={({ field }) => (
              <FormControl
                error={errors[field.name] as string}
                htmlFor={field.name}
                label={
                  <SpaceBetween>
                    {t(
                      "editListingModal.previousListing.warning",
                      "Use previous listing expiration date",
                    )}
                    <Switch
                      checked={!isCustomExpiration}
                      onCheckedChange={checked => {
                        // Only set the default value here instead of in the call to
                        // useForm({defaultValues}) to avoid setting the value and
                        // returning it even when the custom expiration is off.

                        setValue(
                          "newExpirationDate",
                          checked
                            ? undefined
                            : {
                                end: defaultEndDate,
                                start: new Date(),
                              },
                        )

                        setValue("isCustomExpiration", !checked)
                      }}
                    />
                  </SpaceBetween>
                }
                tip={
                  isCustomExpiration || !previousExpiration
                    ? undefined
                    : intlFormat(previousExpiration, {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })
                }
              >
                {isCustomExpiration ? (
                  <OrderDurationSelect
                    duration={{
                      start: new Date(),
                      end: field.value?.end || defaultEndDate,
                    }}
                    id={field.name}
                    variant={ORDER_DURATION_SELECT_VARIANT}
                    onChange={field.onChange}
                  />
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Block>
        <Media lessThan="sm">{footerButtons}</Media>
      </Modal.Body>
      <Media greaterThanOrEqual="sm">
        <Modal.Footer paddingTop={{ _: 0, sm: 0 }}>
          {footerButtons}
        </Modal.Footer>
      </Media>
    </Modal.Form>
  )
}

export const EditListingModal = (props: Props) => {
  return (
    <Suspense fallback={<ModalLoader />}>
      <EditListingModalBase {...props} />
    </Suspense>
  )
}

type BelowFloorPriceTextProps = {
  warningSeverity?: WarningSeverity
}

const BelowFloorPriceText = styled.div<BelowFloorPriceTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props =>
    props.warningSeverity === WarningSeverity.High
      ? css`
          color: ${props.theme.colors.error};
        `
      : css`
          color: ${props.theme.colors.warning};
        `}
`
